<template>
  <p>地址：辽宁省大连高新技术产业园区广贤路133号</p>
  <el-image src="/address.png"></el-image>
</template>

<script>
export default {
  name: "Contact",
};
</script>

<style>
</style>