<template>
  <el-divider
    style="position: fixed; margin-top: 87px; height: 2px"
  ></el-divider>
  <el-row>
    <el-col :span="5"></el-col>
    <el-col :span="14">
      <el-image src="/title.png"></el-image>
      <el-tabs v-model="activeName">
        <el-tab-pane label="首页" name="home">
          <Home></Home>
        </el-tab-pane>
        <!-- <el-tab-pane label="关于我们" name="about">
          <About></About>
        </el-tab-pane>
        <el-tab-pane label="核心产品" name="product">
          <Product></Product>
        </el-tab-pane> -->
        <el-tab-pane label="联系我们" name="contact">
          <Contact></Contact>
        </el-tab-pane>
      </el-tabs>
    </el-col>
    <el-col :span="5"></el-col>
  </el-row>
  <el-footer>
    <el-divider></el-divider>
    <div>版权所有 @ 2021 矢量探索（大连）科技有限公司</div>
    <div>辽ICP备2021005650号-3</div>
  </el-footer>
</template>

<script>
import Home from "./views/Home";
// import About from "./views/About";
// import Product from "./views/Product";
import Contact from "./views/Contact";

export default {
  name: "App",
  components: {
    Home,
    // About,
    // Product,
    Contact,
  },
  data() {
    return {
      activeName: "home",
    };
  },
  methods: {},
};
</script>

<style>
.el-tabs__nav {
  float: right !important;
  margin: 0 20px 10px 0;
}
.el-tabs__header {
  margin: -40px 0 15px !important;
}
.el-footer {
  color: var(--el-text-color-primary);
  text-align: center;
  line-height: 36px;
  height: 130px !important;
  position: fixed;
  bottom: 0;
  width: 100%;
}
</style>
