<template>
  <!-- <el-image src="/dl.jfif"></el-image> -->

  <div style="word-break: break-all; margin: 0px auto">
    <el-image
      src="/dl.jfif"
      style="float: right; margin: 0 0 10px 16px"
    ></el-image>
    <p style="text-indent:2em;">
      矢量探索（大连）科技有限公司是一家专业从事软件开发、软件定制、软件实施的高新技术企业。拥有一批长期从事软件开发、软件定制的专业人才，全方位满足政府及企事业单位信息化需求。
    </p>
    <p style="text-indent:2em;">
      公司依托于高校高端科技资源，集自动控制系统研发及电气元件开发于一体，致力为用户提供全面的工业自动化解决方案，拥有矢量探索智能三维机械手管理系统软件V1.0，矢量探索智能起重机移动端管理软件V1.0,矢量探索智能网关编程软件V1.0,矢量探索监控主机嵌入式软件V1.0,矢量探索车间管理系统V1.0，矢量探索xSCADA监控系统V.10软件著作权及多项完全自主知识产权的专利。主要业务：数据采集系统研发；视觉识别定位系统研发；专用控制系统研发；工业无线系统方案解决；工业现场监控系统方案解决。我公司研发的系统及产品已广泛应用于码头、矿山、机场、起等工业现场，且长期稳定运行。
    </p>
    <p style="text-indent:2em;">
      公司致力于基础软件、嵌入式软件、辅助工程管理软件的开发。其中在数据采集与监视控制系统（SCADA）软件的开发、执行制造系统（MES）软件的开发能力突出、市场实用性强。我司所处东北地区以重工业为主，在重工业转型升级中，具备自主知识产权的核心软件需求迫切，我司以此为切入点，已为多家用户提供软件产品及软件整体解决方案。未来我司会继续秉承自主开发、精益求精、开拓创新的理念，为用户提供完整、稳定、高效的软件产品及服务。
    </p>
  </div>
</template>

<script>
export default {
  name: "Home",
};
</script>

<style>
</style>